import React, { useState } from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { linkResolver } from '../utils/linkResolver'
import Img from "gatsby-image"
import { motion } from "framer-motion";
import Button from "../components/button"

const isMobile = typeof window !== `undefined` ? window.innerWidth < 767 : null

export const query = graphql`
query staffQuery {
    prismic {
      allStaffs {
        edges {
          node {
            first_name
            role
            profile_photo
            profile_photoSharp{
                childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
            }
            _meta {
              type
              uid
            }
          }
        }
      }
    }
  }
`

const StaffPage = ({ data }) => {
    const [selected, setSelected] = useState(null);
    const variants = {
        on: { y: 0 },
        off: { y: "100%" }
    };
    return (
        <div className="body">
            <Layout>
                <SEO title="Staff" />
                <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                    <section className="section">
                        <div className="page-heading-parent">
                            <h1>Meet the Team.</h1>
                            <div className="line grey"></div>
                        </div>
                        <div className="w-layout-grid preview-grid staff">
                            {data.prismic.allStaffs.edges.map(({ node: staffMember }, i) => (
                                <Link to={linkResolver(staffMember._meta)} key={i} >
                                    <motion.div key={i} className="card staff w-inline-block" onHoverStart={() => setSelected(i)} onHoverend={() => setSelected(!i)}>
                                        <div className="card-image">
                                            <Img fluid={staffMember.profile_photoSharp.childImageSharp.fluid} alt="Profile" />
                                        </div>
                                        {isMobile ?
                                            <div className="card-info staff">
                                                <h4>{staffMember.first_name[0].text}</h4>
                                                {staffMember.role[0].text}
                                            </div>
                                            :
                                            <motion.div initial="off" animate={i === selected ? "on" : "off"} transition={{ duration: 0.2 }} variants={variants} className="card-info staff">
                                                <h4>{staffMember.first_name[0].text}</h4>
                                                {staffMember.role[0].text}
                                            </motion.div>
                                        }
                                    </motion.div>
                                </Link>
                            ))}
                        </div>
                        <div className="staff-application-block">
                            <h2>Interested in Joining <em>The Pub?</em></h2>
                            <p><em>The Pub</em> is currently seeking new members! Please subscribe to our newsletter and follow @wheatonpub on Instagram to stay up to date on our recruiting seasons and open opportunities.</p>
                            <div className="button-parent page">
                                <Button name="Position Descriptions" to="https://www.mediafire.com/file/n5gvy49v6nznrcj/Pub_Jobs.pdf/file" />
                                <Button name="Staff Application" to="https://forms.gle/fc42TjwuPD9NkWSY6" />
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </div >
    )
}
export default StaffPage
